<template>
  <div v-if="formData && formData.agendamentoConfiguracao.strValor">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Básicas</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Dias de atendimento da clínica"
            name="arrayDiasAtendimento"
            v-model="formData.agendamentoConfiguracao.strValor.arrayDiasAtendimento"
            :items="bind.arrayDiasAtendimento"
            multiple
            rules="required|array"
          />
          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Horário de Atendimento Inicial"
            name="strHorarioAtendimentoInicial"
            v-model="formData.agendamentoConfiguracao.strValor.strHorarioAtendimentoInicial"
            rules="required"
          />

          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Horário de Atendimento Final"
            name="strHorarioAtendimentoFinal"
            v-model="formData.agendamentoConfiguracao.strValor.strHorarioAtendimentoFinal"
            rules="required"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Notificações</span>

        <div class="row">
          <ed-input-editor
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Mensagem Padrão de Confirmação de Agendamento"
            name="strHorarioAtendimentoFinal"
            v-model="formData.agendamentoConfiguracao.strValor.strMensagemConfirmacao"
            rules="required"
            extraPlugins="edol"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSelect,
  EdInputDate,
  EdInputTime,
  EdInputEditor,
  EdInputAutoComplete,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    EdInputDate,
    EdInputTime,
    EdInputEditor,
    EdInputAutoComplete,
    EdButton,
  },
  inject:['bind'],
  provide() {
    return {};
  },
  mounted() {

  },
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
  },
};
</script>
