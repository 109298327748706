<template>
  <div v-if="bind.boolCarregado">
    <v-tabs v-model="strTab" centered icons-and-text>
      <!-- <v-tab href="#tab-cadastro">
        Configuração Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab> -->
      <v-tab href="#tab-meatoscopia">
        Meatoscopia
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-diagnostico-ocupacional">
        Diagnóstico Ocupacional
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-diagnostico-clinico">
        Diagnóstico Clínico
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-ruido">
        Ruído de Mascaramento
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="null"
      v-if="!this.$root.$session.loadingContent"
      disableFixedSave
    >
      <!-- <cadastro-geral
        v-show="strTab == 'tab-cadastro'"
        :formData="formData"
        :key="'cadastro-geral-' + $root.$session.versao"
      /> -->

      <parametro
        v-if="strTab == 'tab-meatoscopia'"
        strChave="audiometriaMeatoscopia"
        :key="'tipo-meatoscopia-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />
      <parametro
        v-if="strTab == 'tab-diagnostico-ocupacional'"
        strChave="audiometriaDiagnosticoOcupacionalOrelha"
        :key="'tab-diagnostico-ocupacional-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />

      <parametro
        v-if="strTab == 'tab-diagnostico-clinico'"
        strChave="audiometriaDiagnosticoClinicoOrelha"
        :key="'stattab-diagnostico-clinicus-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />

      <parametro
        v-if="strTab == 'tab-ruido'"
        strChave="audiometriaRuidoMascaramento"
        :key="'tab-ruido-' + $root.$session.versao + strKey"
        disableIcon
        disableColor
      />
    </ed-form>
  </div>
</template>

<script>
import Parametro from "@/components/common/parametro/editar";
import CadastroGeral from "./partials/geral.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    Parametro,
    CadastroGeral,
    EdForm,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
      },
      strTab: "tab-meatoscopia",
      strKey: this.$utilidade.randomString(),
      formData: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.bind.boolCarregado = true;
      // this.$root.$dialog.loading(true);
      // Promise.all([
      //   this.$root.$request.get("Common/Parametro", {
      //     strChave: "agendamentoConfiguracao",
      //     boolBuscarSomenteAtivo: false,
      //   }),
      // ]).then(([objConfiguracao]) => {
      //   this.$root.$dialog.loading(false);
      //   if (objConfiguracao.status == 200 && objConfiguracao.result) {
      //     if (!objConfiguracao.result.strValor) {
      //       objConfiguracao.result.strValor = {};
      //     }
      //     this.formData = {
      //       agendamentoConfiguracao: Object.assign(objConfiguracao.result, {}),
      //     };
      //   }
      //   this.bind.boolCarregado = true;
      // });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Common/Parametro", this.formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          this.strKey = this.$utilidade.randomString();
          this.initialize();
        }
      });
    },
  },
};
</script>
