<template>
  <div class="row mb-3">
    <ed-audiometria-config
      :intConsultaId="$route.params.intId"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdAudiometriaConfig from "@/components/audiometria/configuracao/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdAudiometriaConfig },
  beforeDestroy() {},
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
